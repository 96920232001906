import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split  
} from "@apollo/client"
import { createUploadLink } from 'apollo-upload-client'
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from '@apollo/client/link/context';

const {
  REACT_APP_GQL_URI,
  REACT_APP_GQL_BACKEND_ENDPOINT,
  REACT_APP_INCIDENTS_GQL_URI,
  NODE_ENV
} = process.env


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access_token'); 
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      bqtok: token ? token : ''
    }
  }
})


const httpLink = createHttpLink({
  uri: REACT_APP_GQL_URI,
});

const incidentsHttpLink = createHttpLink({
  uri: REACT_APP_INCIDENTS_GQL_URI,
});



const wsLink = new WebSocketLink({
  uri: REACT_APP_GQL_BACKEND_ENDPOINT,
  options: {
    reconnect: true
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  split(
    ({ operationName }) => operationName && operationName.startsWith('IncidentGql'),
    authLink.concat(incidentsHttpLink),
    authLink.concat(httpLink)
  )
);

const client = new ApolloClient({  
  link: splitLink,
  cache: new InMemoryCache(),      
})


export default client