// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import globalEn from '@src/utility/intl/en.json'
import globalEs from '@src/utility/intl/es.json'
import globalBr from '@src/utility/intl/br.json'


// ** Dashboard Language Data
import homeEn from '@src/app/home/intl/en.json'
import homeEs from '@src/app/home/intl/es.json'
import homeBr from '@src/app/home/intl/br.json'

import { flattenMessages } from './flattenMessages'

// ** Menu msg obj
const menuMessages = {
  en: { ...globalEn, ...homeEn },
  es: { ...globalEs, ...homeEs },
  pt: { ...globalBr, ...homeBr }
}

// ** Create Context
const Context = createContext()

const localeLang = localStorage.getItem('locale')

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('es')
  const [messages, setMessages] = useState(flattenMessages(menuMessages['es']))

  useEffect(() => {
    if (localeLang) {
      setLocale(localeLang)
      setMessages(flattenMessages(menuMessages[localeLang]))
    }
  }, [])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(flattenMessages(menuMessages[lang]))
  }
  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='es'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
